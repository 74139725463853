import { setupLayouts } from 'virtual:generated-layouts'
import type { App } from "vue"
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from 'vue-router/auto/routes'

const frontendRoutes = routes.filter(it => it.meta?.namespace == "frontend")
for (const route of frontendRoutes) {
  route.meta = { layout: "frontend/layouts/default", ...route.meta }
}
const routes_with_layout = setupLayouts(frontendRoutes)
console.log(frontendRoutes)

const router = createRouter({
  history: createWebHistory(),
  routes: routes_with_layout,
})

export default function(app: App) {
  app.config.globalProperties.$router = router as any;
  app.use(router)
}
