import { Subscription } from "rxjs";
import { CanceledError } from "axios"
import type { App } from "vue"

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $subscriptions: Subscription[]
  }
}

export default function(app: App) {
  // const app = ctx.app
  // const handleErrors = ctx.handleErrors
  // const router = app.config.globalProperties.$router

  // app.config.globalProperties.$subscriptions = []

  // handleErrors.push(function(err) {
  //   if (err instanceof CanceledError) {
  //     return false
  //   }
  // })

  // router.beforeResolve((to, from) => {
  //   app.config.globalProperties.$subscriptions.reverse().forEach(subscription => {
  //     subscription.unsubscribe()
  //   })

  //   app.config.globalProperties.$subscriptions = []
  // })
}
