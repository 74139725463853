import { createPinia } from 'pinia'
import type { App } from 'vue'

const pinia = createPinia()

export default function(app: App) {
  app.config.globalProperties.$pinia = pinia
  app.use(pinia)
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $pinia: typeof pinia
  }
}
